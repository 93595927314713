
@font-face {
  font-family: 'SFProDisplay';
  src: url('../fonts/SF-Pro-Display-Regular.otf');
}
@font-face {
  font-family: 'SFProDisplayLight';
  src: url('../fonts/SF-Pro-Display-Light.otf');
}
@font-face {
  font-family: 'SFProDisplayBold';
  src: url('../fonts/SF-Pro-Display-Bold.otf');
}


:root {
  --light-orange--color: #FFAE3C;
  --dark-charcoal--color: #2F2F2F;
  --primary-font: 'SFProDisplay', sans-serif;
  --primary-font--light: 'SFProDisplayLight', sans-serif;
  --primary-font--bold: 'SFProDisplayBold', sans-serif;
}

*, ::after, ::before {
  -webkit-box-sizing: border-box;
          box-sizing: border-box
}
* {
  margin: 0;
  padding: 0;
}

body {
  font-family: var(--primary-font--light);
  font-size: 16px;
  color: var(--dark-charcoal--color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-height: 100vh;
 
  &.home {
    background: url(../images/bg.jpg) no-repeat center;
    background-size: cover;
  }
}


// Global
.container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  @media (min-width: 576px) {
    max-width:540px;
  }
  @media (min-width: 768px) {
    max-width:720px;
  }
  
  @media (min-width: 992px) {
    max-width:960px;
  }
  
  @media (min-width: 1200px) {
    max-width:1140px;
  }  

  @media (min-width: 1366px) {
    max-width:1280px;
  }  
}

.txt-primary {
  font-family: var(--primary-font);
}
.txt-primary--light {
  font-family: var(--primary-font--light);
}
.txt-primary--bold {
  font-family: var(--primary-font--bold);
}

.link-btn.inquire {
  display: block;
  background-color: var(--dark-charcoal--color);
  color: #D4E044;
  font-size: 40px;
  text-decoration: none;
  width: 280px;
  padding: 1rem 0;

  &:hover {
    background-color: lighten($color: #2F2F2F, $amount: 5%);
  }

  span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  svg {
    margin-top: auto;
  }
}

.text--linear {
  background-image: -o-linear-gradient(350deg, rgba(7,147,255,1) 0%, rgba(53,120,255,1) 15%, rgba(149,58,255,1) 32%, rgba(183,36,255,1) 48%, rgba(221,60,188,1) 68%, rgba(255,70,139,1) 84%, rgba(255,100,99,1) 100%);
  background-image: -moz-linear-gradient(350deg, rgba(7,147,255,1) 0%, rgba(53,120,255,1) 15%, rgba(149,58,255,1) 32%, rgba(183,36,255,1) 48%, rgba(221,60,188,1) 68%, rgba(255,70,139,1) 84%, rgba(255,100,99,1) 100%);
  background-image: -webkit-linear-gradient(350deg, rgba(7,147,255,1) 0%, rgba(53,120,255,1) 15%, rgba(149,58,255,1) 32%, rgba(183,36,255,1) 48%, rgba(221,60,188,1) 68%, rgba(255,70,139,1) 84%, rgba(255,100,99,1) 100%);
  background-image: linear-gradient(100deg, rgba(7,147,255,1) 0%, rgba(53,120,255,1) 15%, rgba(149,58,255,1) 32%, rgba(183,36,255,1) 48%, rgba(221,60,188,1) 68%, rgba(255,70,139,1) 84%, rgba(255,100,99,1) 100%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  // -webkit-text-fill-color: transparent; 
  // -moz-text-fill-color: transparent;
 
  -webkit-text-fill-color:  rgba(255,255,255, 0); 
  -moz-text-fill-color:  rgba(255,255,255, 0);
}


// Header
body:not(.home) {
  header {
    background: url(../images/header-bg.jpg) no-repeat center;
    background-size: cover;
  }
}
.header-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 52px;

  @media (min-width: 768px) {
    height: 80px;
  }
}
.menu-link {
  font-size: 14px;
  color: white;
  text-decoration: none;
  text-transform: uppercase;

  &:not(:last-of-type) {
    margin-right: 25px;
  }

  &.active {
    color: #FFBE63;
  }

  @media (min-width: 768px) {
    font-size: 18px;

    &:not(:last-of-type) {
      margin-right: 50px;
    }
  }
}
.logo-img {
  width: 50px;
  height: auto;

  @media (min-width: 768px) {
    width: auto;
  }
}


// Footer
footer {
  margin-top: auto;
}

.home .footer__inner {
  color: white;
}
.footer__inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 16px;
  color: #2F2F2F;
  padding: 36px 0;

  @media (min-width: 992px) {
    font-size: 22px;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
}
.footer__links {
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-bottom: 1rem;
  padding: 0;

  @media (min-width: 992px) {
    margin-bottom: 0;
  }

  li {
    &:not(:last-of-type) {
      margin-right: 28px;
    }

  }
}



// Container
.main-content {
  padding: 1rem 0;
}



// Hero
section.hero {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 33px;

  @media (min-width: 992px) {
    margin-bottom: 74px;
  }

  img {
    width: 100%;
    margin-top: 2rem;

    @media (min-width: 768px) {
      width: auto;

    }
  }
  
}
.hero-intro {
  font-size: 25px;
  font-weight: initial;
  color: var(--light-orange--color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;

  @media (min-width: 992px) {
    font-size: 50px;
  }
}



// Showcase
.showcase {
  padding-top: 2rem;
  font-family: var(--tertiary--font);

  @media (min-width: 768px) {
    padding-top: 3rem;
  }

  .list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  .item {
    width: 100%;
    height: auto;

    img {
      width: 100%;
      height: 100%;
      -o-object-fit: cover;
         object-fit: cover;
    }
  
    @media (min-width: 992px) {
      width: 50%;
    }
    @media (min-width: 1200px) {
      height: 325px;
    }
  }
}


// Contact
.contact-inner {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  color: white;
  font-size: 22px;

  @media (min-width: 992px) {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }

  &> div {
    width: 100%;
    border-radius: 24px;
    background-color: rgba(255, 255, 255, .10%);
    padding: 22px 23px;
    margin-bottom: 8px;

    @media (min-width: 992px) {
      width: calc(50% - 8px);
      padding: 46px 40px;
      margin-right: 16px;
    }
  
    
    &> .contract_intro {
      margin-bottom: 12px;
    }

  }
  &> div:first-of-type {
    font-size: 18px;
    margin-right: 16px;

    @media (min-width: 992px) {
      font-size: 22px;
    }

    .contact-intro {
      font-family: var(--primary-font--bold);
      font-size: 24px;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: end;
          -ms-flex-align: end;
              align-items: flex-end;
      margin-bottom: 6px; 

      @media (min-width: 768px) {
        font-size: 35px;
        margin-bottom: 9px; 
      }
    
      &> svg {
        margin-left: 6px;
      }
    }
  }
  &> div:last-of-type {
    .contact-intro {
      font-size: 22px;
    }
    .tools {
      list-style-type: none;
      margin: 12px 0 23px;
      padding: 0;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start;
      
      li {
        background-color: rgba(0, 28, 51, .50%);
        border-radius: 10px;
        width: 74px;
        height: 74px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        
        &:not(:last-of-type) {
          margin-right: 20px;
        }
      }
    }
  }
  .contact-cta {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-top: 24px;

    @media (min-width: 768px) {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-direction: row;
              flex-direction: row;
    }

    @media (min-width: 992px) {
      margin-top: 42px;
    }
  }

  .btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    width: 207px;
    height: 58px;
    color: white;
    border-radius: 10px;
    text-decoration: none;
    font-size: 16px;


    @media (min-width: 992px) {
      font-size: 18px;
    }

    &.btn-send {
      background: -o-linear-gradient(
        350deg, rgba(252,74,26,1) 0%, rgba(247,183,51,1) 100%);
      background: linear-gradient(
        100deg, rgba(252,74,26,1) 0%, rgba(247,183,51,1) 100%);
      margin-right: 10px;
      margin-bottom: 8px;

      @media (min-width: 768px) {
        margin-bottom: 0;
      }

      &:hover {
        background: lighten(#fc4a1a, 10%);
      }
  
    }

    
    &.btn-hire {
      border: 2px solid #F2994A;
      
      &:hover {
        background:#F2994A
      }
    
    }

    svg {
      margin-left: 6px;
    }
  }
}

.social {
  list-style-type: none;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-top: 12px;

  li {
    &:not(:last-of-type) {
      margin-right: 20px;
    }
  }
}


.header--fixed:not(.home) {
  padding-top: 52px;

  @media (min-width: 1200px) {
    padding-top: 80px;
  }

  header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
  }
}