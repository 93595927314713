
.intro {
    font-size: 16px;
    color: #7C7C7C;
    padding-top: 21px;
    margin-bottom: 35px;

    @media (min-width: 992px) {
        width: 50%;
    }

    &__heading {
        font-size: 30px;
        margin-bottom: 4px;
        font-weight: initial;

        @media (min-width: 992px) {
            font-size: 32px;

        }
    }

    img {
        width: 100%;
    }
}


.quote {
    padding: 44px 0 58px;

    @media (min-width: 992px) {
        padding: 64px 0 78px;
    }
}
.quote-inner {
    max-width: 670px;
    font-family: var(--secondary--font);
   
    color: var(--dark-charcoal--color);
    text-align: center;
    margin: 0 auto;

    strong {
        font-family: var(--secondary--font);
    }

    p {
        font-size: 22px;
        line-height: 1.2;
        margin-bottom: 0;

        @media (min-width: 992px) {
            font-size: 45px;
        }
    }

    span {
        font-family: var(--tertiary-italic--font);
        font-size: 16px;
        color: #8A8A8A;
    }
}

.app {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;

    @media (min-width: 992px) {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
     
    }
}
.app-screenshot {
    width: 100%;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
    border-radius: 30px;
    margin-bottom: 12px;

    &.side {
        display: none;
    }
    &:not(.side) {
        display: block;
    }


    @media (min-width: 992px) {
        width: 50%;
        margin-bottom: 0;
      
        &.side {
            display: block;
        }
        &:not(.side) {
            display: none;
        }
    }
}
.app-heading {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    margin-bottom: 18px;

    @media (min-width: 768px) {
        margin-bottom: 20px;
    }
}
.app-logo {
    width: 55px;
    height: 55px;
    -o-object-fit: contain;
       object-fit: contain;
    margin-right: 9px;

    @media (min-width: 768px) {
        width: 70px;
        height: 70px;
        margin-right: 25px;
    }
}
.app-name {
    font-family: var(--primary-font--bold);
    font-size: 18px;
    line-height: 1.2;

    @media (min-width: 768px) {
        font-size: 25px;
    }
}
.app {
    &:not(:last-of-type) {
        margin-bottom: 50px;
    }
    @media (min-width: 992px) { 
        &:not(:last-of-type) {
            margin-bottom: 70px;
        }
        &:nth-child(odd) .app-content {
            padding-left: 54px;
        }
        &:nth-child(even) .app-content {
            padding-right: 54px;
        }
    }
}

.app-desc {
    font-family: var(--primary-font);

    p {
        text-align: justify;
    }
}
.app-task {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    color: #7C7C7C;
    margin-top: 16px;

    @media (min-width: 768px) {
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: start;
    }

    ul {
        list-style-type: none;
        &:first-of-type {
            margin-right: 1rem;
        }

        @media (min-width: 768px) {
            &:first-of-type {
                margin-right: 86px;
            }
        }
    }

    li {
        font-family: var(--primary-font--light);
    }
} 
.app-tools {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    color: #7C7C7C;
    margin-top: 1rem;

    @media (min-width: 1024px) {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        margin-top: 33px;
    }

    div {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: start;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: start;

        &:first-of-type {
            margin-bottom: 12px;
        }

        &.site-link {
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
                -ms-flex-direction: column;
                    flex-direction: column;
        }

        @media (min-width: 768px) {
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;

            &:first-of-type {
                margin-right: 54px;
                margin-bottom: 0;
            }

            &.site-link {
                -webkit-box-orient: horizontal;
                -webkit-box-direction: normal;
                    -ms-flex-direction: row;
                        flex-direction: row;
            }
        }

     
        span {
            margin-right: 8px;
        }

        img:first-of-type {
            margin-right: 11px;
        }

        a {
            color: #D18405;

        }
    }
}

.luntian {
    padding: 0 15px;
    margin-top: 40px;

    @media (min-width: 992px) {
        padding: 0;
    }

    &__inner {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        background: -o-linear-gradient(354.94deg, #8128CE -0.65%, #4008B3 100.6%);
        background: linear-gradient(95.06deg, #8128CE -0.65%, #4008B3 100.6%);
        border-radius: 30px;
        padding: 26px 14px;


        @media (min-width: 992px) {
            -webkit-box-orient: horizontal;
            -webkit-box-direction: normal;
                -ms-flex-direction: row;
                    flex-direction: row;
            -webkit-box-pack: justify;
                -ms-flex-pack: justify;
                    justify-content: space-between;
            -webkit-box-align: center;
                -ms-flex-align: center;
                    align-items: center;
            padding: 63px 56px;
        }
    }
    &__heading {
        font-size: 22px;
        margin-bottom: 10px;
        @media (min-width: 992px) {
            font-size: 30px;
        }
    }
    &__info {
        width: 100%;
        color: white;
        margin-bottom: 1rem;

        @media (min-width: 992px) {
            width: calc(50% - 60px);
            margin-bottom: 0;
        }

        p {
            font-size: 16px;
            margin-bottom: 7px;
        }

        a {
            color: #FFAE3C;

        }
    }
    &__photos {
        width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: start;
        @media (min-width: 992px) {
            width: calc(calc(50% + 45px) - 6px);
        }
        img {
            width: calc(33.33% - 9px);
            min-height: auto;
            -o-object-fit: contain;
            object-fit: contain;

            &:not(:last-of-type) {
                margin-right: 9px;
            }

            @media (min-width: 992px) {
                width: 33.33%;
            }
        }
    }
}